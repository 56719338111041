import { defaultTheme } from 'react-admin';

const theme = {
    ...defaultTheme,
    components: {
        ...defaultTheme.components,
        MuiAppBar: {
            styleOverrides: {
              root: {
                  backgroundColor: "#0c2d57"
              }
           }
        },
        MuiButton: {
            styleOverrides: {
                textPrimary: {
                    color: "#fa6736"
                }
             }
        }
    }
};

export default theme;