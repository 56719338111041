import * as React from "react";
import {
    required,
    List, 
    SimpleShowLayout,
    Datagrid, 
    EditButton,
    Show,
    Edit,
    Create,
    Filter,
    SimpleForm,
    TextField, 
    TextInput,
    SelectInput,
    ReferenceInput,
    TopToolbar,
    CreateButton,
    ExportButton,
    ReferenceField,
} from 'react-admin';
import { Grid } from '@mui/material';
import { BulkDeleteButton } from 'react-admin';
import { ImportButton } from "react-admin-import-csv";
import Breadcrumb from '../components/breadcrumb';
import SyncButton from "../components/syncButton";
import PostShowActions from "../components/postShowActions";

const BulkActionButtons = props => (
    <BulkDeleteButton {...props} />
)

const ListActions = () => {
    return (
      <TopToolbar>
        <CreateButton />
        <ExportButton />
        <ImportButton />
        <SyncButton resource="items" />
      </TopToolbar>
    );
  };

export const ItemList = () => {
    return (
        <Grid container spacing={2}>
            <Breadcrumb title={ 'Items' } />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <List filters={<ItemFilter />} actions={ <ListActions />} title="Item" bulkActionButtons={<BulkActionButtons />}>
                        <Datagrid rowClick="show">
                            <TextField source="name" />
                            <ReferenceField label="Type" source="type" reference="item-types" link={ false } >
                                <TextField source="name" />
                            </ReferenceField>
                            <EditButton />
                        </Datagrid>
                    </List>
                </Grid>
            </Grid>
        </Grid>
    );
}

export const ItemShow = (props) => (
    <Show {...props} actions={<PostShowActions resource="items" isEdit={true} />}>
        <Grid container paddingBottom={2} spacing={2}>
            <Grid item xs={12}>
                <SimpleShowLayout>
                    <TextField source="id" />
                </SimpleShowLayout>
            </Grid>
        </Grid>
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <SimpleShowLayout>
                    <TextField source="name" />
                </SimpleShowLayout>
            </Grid>
            <Grid item xs={6}>
                <SimpleShowLayout>
                    <ReferenceField label="Type" source="type" reference="item-types" link={ false } >
                        <TextField source="name" />
                    </ReferenceField>
                </SimpleShowLayout>
            </Grid>
        </Grid>
    </Show>
);

export const ItemEdit = props => {
    return (
        <Edit {...props}>
            <SimpleForm>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextInput source="name" validate={required()} fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <ReferenceInput source="type" reference="item-types">
                            <SelectInput validate={required()} optionText="name" optionValue="id" fullWidth />
                        </ReferenceInput>
                    </Grid>
                </Grid>
            </SimpleForm>
        </Edit>
    );
}

export const ItemCreate = props => {
    return (
        <Create {...props}>
            <SimpleForm>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextInput source="name" validate={required()} fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <ReferenceInput source="type" reference="item-types">
                            <SelectInput validate={required()} optionText="name" optionValue="id" fullWidth />
                        </ReferenceInput>
                    </Grid>
                </Grid>
            </SimpleForm>
        </Create>
    );
}

export const ItemFilter= props => (
    <Filter { ...props }>
        <TextInput label="Search" source="keyword" alwaysOn />
        <ReferenceInput source="type" reference="item-types" alwaysOn>
            <SelectInput optionText="name" optionValue="id" fullWidth/>
        </ReferenceInput>
    </Filter >
);