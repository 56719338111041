import * as React from "react";
import { 
  SupervisedUserCircle, ListRounded, ListAlt, ConfirmationNumber, ClassOutlined, 
  ArticleOutlined, CalendarTodayOutlined, BusinessOutlined, SettingsOutlined, BlockOutlined, 
  DevicesOutlined, MessageOutlined , BadgeOutlined, EmojiEventsOutlined, DiscountOutlined, GroupsOutlined, ContactsOutlined,
  CalendarViewDay, AddCommentOutlined
} from '@mui/icons-material';
import { Admin, Resource } from 'react-admin';
import { loadCSS } from 'fg-loadcss';
import { UserList, UserEdit, UserCreate, UserShow } from './resources/users';
import { ItemList, ItemEdit, ItemCreate, ItemShow } from './resources/items';
import { TicketTypeList, TicketTypeCreate, TicketTypeEdit, TicketTypeShow } from './resources/ticket_types';
import { TicketList, TicketCreate, TicketEdit, TicketShow } from './resources/tickets';
import { TermList, TermShow } from './resources/terms';
import { PostList, PostShow } from './resources/posts';
import { EventList, EventShow } from './resources/events';
import { DeviceList, DeviceShow } from './resources/devices';
import { UserTypeList, UserTypeShow, UserTypeCreate, UserTypeEdit } from './resources/user_types';
import { PartnershipList, PartnershipShow } from './resources/partnerships';
import { SettingList, SettingCreate, SettingEdit, SettingShow } from './resources/settings';
import { NotificationList, NotificationCreate, NotificationEdit, NotificationShow } from './resources/notifications';
import { BlockList, BlockCreate, BlockEdit, BlockShow } from './resources/blocks';
import { BenefitList, BenefitCreate, BenefitEdit, BenefitShow } from './resources/benefits';
import { CouponList, CouponCreate, CouponEdit, CouponShow } from './resources/coupons';
import { AttendeeList, AttendeeCreate, AttendeeEdit, AttendeeShow } from './resources/attendees';
import dataProvider from './dataProvider';
import authProvider from './authProvider';
import CustomMenu from './components/menu';
import CustomTheme from './components/theme';
import CustomLayout from "./components/layout";
import { ContactCreate, ContactEdit, ContactList, ContactShow } from "./resources/contacts";
import { AppointmentCreate, AppointmentEdit, AppointmentList, AppointmentShow } from "./resources/appointments";
import { RequestCreate, RequestEdit, RequestList, RequestShow } from "./resources/requests";

const App = () => {
  //Load fontawesome
  React.useEffect(() => {
    const node = loadCSS(
      'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
      document.querySelector('#font-awesome-css'),
    );

    return () => {
      node.parentNode.removeChild(node);
    };
  }, []);

  const isAdmin = (permission) => permission === 'admin';
  const isModerator = (permission) => permission === 'admin' || permission === 'moderator';

  return (
    <Admin title="The Vibexpo Admin Portal" menu={ CustomMenu } theme={CustomTheme} layout={CustomLayout} dataProvider={dataProvider} authProvider={authProvider} requireAuth>
      {
        permission => [
          isModerator(permission) && <Resource name="users" list={UserList} edit={UserEdit} create={UserCreate} show={UserShow} icon={SupervisedUserCircle} />,
          isModerator(permission) && <Resource name="user-types" options={{ label: 'User Types' }} list={UserTypeList} edit={UserTypeEdit} create={UserTypeCreate} show={UserTypeShow} icon={BadgeOutlined} />,
          isModerator(permission) && <Resource name="benefits" list={BenefitList} edit={BenefitEdit} create={BenefitCreate} show={BenefitShow} icon={EmojiEventsOutlined} />,
          isModerator(permission) && <Resource name="coupons" list={CouponList} edit={CouponEdit} create={CouponCreate} show={CouponShow} icon={DiscountOutlined} />,
          isAdmin(permission) && <Resource name="requests" list={RequestList} edit={RequestEdit} create={RequestCreate} show={RequestShow} icon={AddCommentOutlined} />,
          isAdmin(permission) && <Resource name="contacts" list={ContactList} edit={ContactEdit} create={ContactCreate} show={ContactShow} icon={ContactsOutlined} />,
          isAdmin(permission) && <Resource name="ticket-types" options={{ label: 'Seasons' }} list={TicketTypeList} edit={TicketTypeEdit} create={TicketTypeCreate} show={TicketTypeShow} icon={ListAlt} />,
          isModerator(permission) && <Resource name="tickets" list={TicketList} edit={TicketEdit} create={TicketCreate} show={TicketShow} icon={ConfirmationNumber} />,
          isAdmin(permission) && <Resource name="devices" list={DeviceList} show={DeviceShow} icon={DevicesOutlined} />,
          isAdmin(permission) && <Resource name="notifications" list={NotificationList} edit={NotificationEdit} create={NotificationCreate} show={NotificationShow} icon={MessageOutlined} />,
          isAdmin(permission) && <Resource name="events" list={EventList} show={EventShow} icon={CalendarTodayOutlined} />,
          isAdmin(permission) && <Resource name="attendees" list={AttendeeList} show={AttendeeShow} create={AttendeeCreate} edit={AttendeeEdit} icon={GroupsOutlined} />,
          isAdmin(permission) && <Resource name="partnerships" list={PartnershipList} show={PartnershipShow} icon={BusinessOutlined} />,
          isAdmin(permission) && <Resource name="appointments" list={AppointmentList} edit={AppointmentEdit} create={AppointmentCreate} show={AppointmentShow} icon={CalendarViewDay} />,
          isAdmin(permission) && <Resource name="items" list={ItemList} edit={ItemEdit} create={ItemCreate} show={ItemShow} icon={ListRounded} />,
          isAdmin(permission) && <Resource name="posts" list={PostList} show={PostShow} icon={ArticleOutlined} />,
          isAdmin(permission) && <Resource name="terms" list={TermList} show={TermShow} icon={ClassOutlined} />,
          isAdmin(permission) && <Resource name="blocks" list={BlockList} edit={BlockEdit} create={BlockCreate} show={BlockShow} icon={BlockOutlined} />,
          isAdmin(permission) && <Resource name="settings" list={SettingList} edit={SettingEdit} create={SettingCreate} show={SettingShow} icon={SettingsOutlined} />,
          <Resource name="media" />,
          <Resource name="item-types" />,
          <Resource name="taxonomies" />,  
          <Resource name="roles" />,  
          <Resource name="post-status" />,
          <Resource name="request-status" />,
          <Resource name="status" />      
        ]
      }
    </Admin >
  )
};

export default App;
